<template>
    <div class="container">
        <top-bar ref="topBar" ></top-bar>
        <!-- banner -->
        <div class="banner">
          <div class="bannerDiv" >
            <el-carousel  :interval="5000" arrow="always" :height="autoHeight">
              <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <img :style="{cursor:item.link?'pointer':'initial'}"  style="width:100%;height:auto;" @click="clickBanner(item.link)" :src="item.imgUrl" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 面包屑 -->
        <div class="bread">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/services/sale_support.html">服务支持</a></el-breadcrumb-item>
            <el-breadcrumb-item v-if="$route.name=='Videos'">视频资料</el-breadcrumb-item>
            <el-breadcrumb-item v-else>{{singleVideoContent.title}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 内容 -->
        <div class="main" >
          <el-row :gutter="0" style="width:100%">
            <el-col :xs="24" :sm="24" :md="6" :lg="5" :xl="4" style="padding:0px;">
                <div :style="{width:isMoPhone?'100%':'93%', marginBottom:isMoPhone?'15px':'0px'}" >
                    <!-- 热门搜索 -->
                    <div class="hotSearch">
                        <div class="hotTitle" >热门搜索</div>
                        <div class="hotContent">
                            <div class="hotText" v-for="(item,index) in hotTextArr" :key="index" @click="clickHotText(item)">{{item.title}}</div>
                        </div>
                    </div>
                    <side-bar ref="sideBar"  :asideList="asideList" :activeMenu="activeMenu" @currentAside = currentAside>
                    </side-bar>
                    <img class="videoImg" @click="clickVideoImg('http://www.wch.cn/videos/BluetoothMesh.html')"  style="margin-top:10px" src="@/assets/image/services/video1.jpeg" alt="">
                    <img class="videoImg" @click="clickVideoImg('https://www.wch.cn/videos/USB-to-serial-port-chip-driver-installation-on-Windows.html')" src="@/assets/image/services/video2.jpeg" alt="">
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="18" :lg="19" :xl="20" style="padding:30px;background:#fff;">
               <div v-loading="loading" class="content">
                    <div class="topTitle">{{$route.name=='Videos'?'视频资料':singleVideoContent.title}}</div>
                    <hr  class="hr">
                    <div class="videosPart" v-if="$route.name=='Videos'">
                        <el-row :gutter="0" style="width:100%">
                            <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="6" v-for="(item,index) in content" :key="index" style="padding:0px;" class="videoItem">
                                <div class="video_" @click="clickVideoCard(item)">
                                    <img class="video_img" :src="item.img" alt="">
                                    <div class="video_title">{{item.title}}</div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="singleVideo" v-else>
                        <video width="100%" height="100%" :poster="singleVideoContent.img" controls ref="videoPlayer">
                          <source :src="videoMp4" type="video/mp4">
                          <source :src="videoOgg" type="video/ogg">
                          <source :src="videoFlv" type="video/flv">
                        </video>
                        <div class="tips">
                            <span style="color: #1ea9fb; font-size:14px; font-weight: bolder;">简介：</span>{{singleVideoContent.content}}
                        </div>
                    </div>
               </div>
            </el-col>
          </el-row>
        </div>
        <!-- 底部 -->
        <footer-part ref="footerPart"></footer-part>
    </div>
</template>
<script>
import topBar from '@/pages/topbar.vue'
import footerPart from '@/pages/components/footer.vue'
import sideBar from '@/pages/components/sidebar'
import { videos } from '@/services/serviceSupport.js'
export default {
  name: "videos",
  components:{
    topBar,
    footerPart,
    sideBar,
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        autoHeight:'',
        activeMenu:{},
        bannerList:[
          {imgUrl:require('@/assets/image/services/banner1.png'), link:'https://www.wch.cn/products/CH585.html'},
          {imgUrl:require('@/assets/image/services/banner2.png'), link:'https://www.wch.cn/products/CH32V317.html'},
          {imgUrl:require('@/assets/image/services/banner3.png'), link:'https://www.wch.cn/products/CH634.html'},
        ],
        asideList:[
          {
            asideName:'',
            childList:[
              {key:'1', asideName:'产品手册', path:'/downloads/category/27.html'},
              {key:'2', asideName:'开发资源', path:'/downloads/category/28.html'},
              {key:'3', asideName:'驱动程序', path:'/downloads/category/67.html'},
              {key:'4', asideName:'工具软件', path:'/downloads/category/30.html'},
              {key:'5', asideName:'其他', path:'/downloads/category/29.html'},
              {key:'6', asideName:'视频资料', path:'/videos.html'},
              {key:'7', asideName:'联系我们', path:'/contact_us.html'},
            ]
          }
        ],
        hotTextArr:[
            {title:"BLE单片机", link:"https://www.wch.cn/products/productsCenter?categoryId=5"},
            {title:"RISC-V", link:"https://www.wch.cn/search.html?q=RISC-V&t=all"},
            {title:"USB单片机", link:"https://www.wch.cn/products/productsCenter?categoryId=5"},
            {title:"MCU", link:"https://www.wch.cn/products/productsCenter?categoryId=5"},
            {title:"USB PD", link:"https://www.wch.cn/search.html?q=USB+PD&t=all"},
            {title:"以太网", link:"https://www.wch.cn/search.html?q=以太网&t=all"},
            {title:"USB转串口", link:"https://www.wch.cn/search.html?q=USB转串口&t=all"},
            {title:"Ethernet", link:"https://www.wch.cn/search.html?q=Ethernet&t=all"},
            {title:"serial", link:"https://www.wch.cn/search.html?q=serial&t=all"},
        ],
        loading:false,
        content:[],
        singleVideoContent:{},
        videoMp4: '',
        videoOgg: '',
        videoFlv: ''
    };
  },
  computed: {
    viewKey() {
       return this.$route.path
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  // beforeDestroy() {
  //   this.$bus.$off("clickMenu_videos");
  // },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 992) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    console.log('mounted--')
    if (this.windowWidth < 992) {
      this.isMoPhone = true
    }else{
      this.isMoPhone = false
    }
    this.activeMenu = {key:'6', asideName:'视频资料', path:'/videos.html'}
    this.getAutoHeight()
    if(this.$route.name=='Videos'){
        document.title = this.activeMenu.asideName+' - 南京沁恒微电子股份有限公司'
        this.getContent()
    }else{
        this.getSingleVideo(this.$route.params.name)
    }
    
    // this.$bus.$on("clickMenu_videos", () => {
    //   this.getContent()
    // })
  },
  activated(){
    console.log('activated')
    this.activeMenu = {key:'6', asideName:'视频资料', path:'/videos.html'}
    this.getAutoHeight()
    if(this.$route.name=='Videos'){
        document.title = this.activeMenu.asideName+' - 南京沁恒微电子股份有限公司'
        this.getContent()
    }else{
        this.getSingleVideo(this.$route.params.name)
    }
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
      this.getAutoHeight()
    },
    getAutoHeight(){
      let _w = document.documentElement.clientWidth || document.body.clientWidth; 
      this.autoHeight = _w * 300 / 1920 + 'px'; //banner图在1920像素设计稿下是300px高度
    },
    //点击侧边栏
    currentAside(item){
      console.log('点击的menu',item)
      this.activeMenu = item
      document.title = this.activeMenu.asideName+' - 南京沁恒微电子股份有限公司'
      this.$router.push({ path: item.path,})
      if(item.path=='/videos.html'){
        this.getContent()
      }
    },
    //点击banner
    clickBanner(link){
      if(link){
        window.open(link)
      }
    },
    //点击热门搜索
    clickHotText(t){
      window.location.href = t.link
    },
    //点击左下视频图片
    clickVideoImg(link){
      window.open(link)
    },
    //获取视频列表
    getContent(){
        this.content = []
        this.loading = true
        videos().then(res => {
            if(res.status===200){
                this.content = res.data.data
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false
        });
    },
    //点击视频卡片
    clickVideoCard(t){
        this.$router.push({ name: 'singleVideo',params: {name:t.name+'.html'}})
        this.getSingleVideo(t.name)
    },
    //获取单个视频信息
    getSingleVideo(name){
        this.singleVideoContent = {}
        this.videoMp4 = ''
        this.videoOgg = ''
        this.videoFlv = ''
        this.loading = true
        let n = ''
        if(name.indexOf('.html')>-1){
            n = name.split('.html')[0]
        }else{
            n = name
        }
        videos({videoName:n}).then(res => {
          if(res.status===200){
            this.singleVideoContent = res.data.data[0]
            this.videoMp4 = 'https://www.wch.cn/uploads/video/' + this.singleVideoContent.mp4
            this.videoOgg = 'https://www.wch.cn/uploads/video/' + this.singleVideoContent.ogg
            this.videoFlv = 'https://www.wch.cn/uploads/video/' + this.singleVideoContent.flv
            document.title = this.singleVideoContent.title+' - 南京沁恒微电子股份有限公司'
            this.$refs.videoPlayer.load();
          }else{
              this.$message.error(res.data.message);
          }
          this.loading = false
        });

    }
   
   
    
  }
};
</script>
<style scoped lang="less">
 .container{
    width: 100%;
    margin: 0 auto;
    margin-top: 98px;
    overflow: auto;
 }
 .bannerDiv{
    // .el-carousel__container{
    //   height:300px
    // }
    // .bannerImg{
    //   width:100%; 
    //   height:300px
    // }
  }
 .bread{
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    font-size: 18px;
 }
 .p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
 }
 .main {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 4px;
    .hotSearch{
            min-height: 30px;
            background-color: #ffffff;
            margin-bottom: 10px;
            box-shadow: -2px 1px 10px 1px rgba(159,159,159,.2);
            padding: 10px;
        .hotTitle{
            font-size: 16px;
            line-height: 20px;
            margin: 10px 0px ;
            display: block;
            color: #000000;
            border-left: 2px transparent solid;
            text-align: left;
        }
        .hotContent{
            display: flex;
            flex-wrap: wrap;
            .hotText{
                padding-left: 8px;
                width: 50%;
                box-sizing: border-box;
                background-color: #fff;
                color: #1ea9fb;
                cursor: pointer;
                text-align: left;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .videoImg{
        width: 100%;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .content{
        width: 100%;
        margin: 0 auto;
        border-radius: 4px;
        text-align: left;
        .topTitle{
            font-size: 22px;
            color: #002c85;
            font-weight: 600;
            letter-spacing: 0;
            margin-bottom: 20px;
        }
        .hr{
            border-top: 1px solid #cbd0d4;
            margin: 25px 0px;
        }
        .videosPart{
            .videoItem{
                .video_{
                    margin: 0px 15px 20px;
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    cursor: pointer;
                    .video_img{
                        width: 100%;
                        border-bottom: 1px solid #ddd;
                    }
                    .video_title{
                        font-size: 14px;
                        font-weight: 600;
                        color: #337ab7;
                        padding: 10px 8px;
                        word-break: break-all;
                        &:hover{
                            text-decoration: underline;
                            color: #23527c;
                        }
                    }

                }
                

            }
        }
        .singleVideo{
            .tips{
                margin-top: 20px;
                font-size: 14px;
                word-break: break-all;
            }

        }
    }
 }
 
@media screen and (max-width: 1400px) {
    // .bannerDiv /deep/.el-carousel__container{
    //    height:250px
    // }
    // .bannerDiv .bannerImg{
    //     height:250px
    // }
}
@media screen and (max-width: 1100px) {
    // .bannerDiv /deep/.el-carousel__container{
    //    height:220px
    // }
    // .bannerDiv .bannerImg{
    //     height:220px
    // }
}
@media screen and (max-width: 800px) {
    // .bannerDiv /deep/.el-carousel__container{
    //    height:180px
    // }
    // .bannerDiv .bannerImg{
    //     height:180px
    // }
}
@media screen and (max-width: 500px) {
    // .bannerDiv /deep/.el-carousel__container{
    //    height:140px
    // }
    // .bannerDiv .bannerImg{
    //     height:140px
    // }
}
</style>